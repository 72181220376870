.tournament-details svg {
    color: gray !important;
    width: 20px !important;
}
.plan-header {
    width: 100%;
    background-image: url(../../../assets/images/thumbnails/event-card-thumbnail-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10px;
    border-radius: 16px 16px 0 0;
    position: relative;
}
.plan-header img {
    width: 100%;
    object-fit: cover;
    height: auto;
    min-height: auto;
}

.plan-header .header-sub-body .event-card-globals {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: end;
}
.plan-header .header-sub-body .event-card-globals .even-fund {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: fit-content;
    height: fit-content;
    font-size: 18px;
    font-weight: bold;
    background: linear-gradient(153.06deg, #08c8f6 -0.31%, #4d5dfb 99.69%);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}
.tournament-date {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    flex-grow: 0;
    gap: 10px;
}
.plan-header .header-sub-body .event-card-globals .event-time-discount {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    font-size: 10px;
    width: fit-content;
    height: fit-content;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);
    border-radius: 12px;
}
.tournament-tab.tab {
    display: block !important;
}
.tour-share {
    overflow-x: auto;
}
.tour-share img {
    width: 48px !important;
    height: 48px !important;
    border-radius: 50%;
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .tour-plan-side {
        padding: 72px 0 0 0 !important;
        gap: 20px !important;
    }
    .tour-plan-side-2 {
        position: absolute;
    }
    .tour-form-side-2 .tour-form-header {
        position: relative !important;
    }
    .tour-form-side-2 .modal-return {
        z-index: 1;
    }
    .tournament-tab {
        padding: 0 10px;
    }
    .tour-game-id {
        padding: 0 10px !important;
    }
    .tour-share {
        margin: 0 10px;
        max-width: calc(100% - 20px);
    }
    .plan-header .header-sub-body .event-card-globals .even-fund,
    .tournament-date {
        flex-grow: 1 !important;
        width: 50% !important;
    }
    .event-time-discount {
        background: rgba(0, 0, 0, 0.3) !important;
    }
    .tour-plan-side .modal-return {
        position: absolute;
        top: 80px;
        left: 10px;
        background-color: rgba(0, 0, 0, 0.2) !important;
        z-index: 1;
    }
    .tour-form-side-2 {
        width: 100%;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
    }

    .tour-form-side-2 .tour-plan-action {
        width: 100%;
        position: relative !important;
    }
}

@media (min-width: 568px) {
    .tour-form-side.mobile-hide {
        display: block !important;
    }
}
.show-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.show-details {
    color: rgb(var(--vert-500));
}

.tour-game-id .description {
    flex-grow: 1;
}
.tour-forms .form-container {
    gap: 0 !important;
}
.opaced.success {
    background: var(--vert-500) !important;

    color: #fff !important;
}
.opaced.success:hover {
    opacity: 0.8;
}
