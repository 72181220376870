.private-party-lock svg {
    color: var(--yelow-500);
}
.public-party-unlock svg {
    color: var(--vert-500);
}
.game-card {
    animation: 100ms linear;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
}

.game-card:hover {
    border: 1px solid var(--vert-500);
    cursor: pointer;
    animation: borderExpand 0.5s forwards;
}
.game-card.is-competition {
    background: var(--linear-orange2) !important;
}
.game-card.is-competition:hover {
    border: 1px solid #ee9617 !important;
}
.game-card.blocked-user {
    background: var(--linear-pink) !important;
}
.game-card.blocked-user:hover {
    border: 1px solid #ffc7c7 !important;
}
.game-card.cannot-join {
    background: var(--linear-gray) !important;
}
.game-card.cannot-join:hover {
    border: 1px solid #eee !important;
}
.special-mode-icon {
    color: #808080 !important;
    height: 25px !important;
    width: 25px !important;
}

.special-mode-icon svg {
    color: gray !important;
    height: 25px !important;
    width: 25px !important;
    color: var(--vert-500) !important;
}
.event-share {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    background: rgba(255, 255, 255, 0.15) !important;
}
.event-share:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    outline: var(--vert-50);
}
.event-card-button.success {
    background-color: var(--vert-500) !important;
    color: #fff !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event-card-button.danger {
    background-color: rgb(var(--error-500-rgb)) !important;
    color: #fff !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event-card-button.success:hover,
.event-card-button.danger:hover {
    opacity: 0.8;
}
.event-card:hover {
    border: 1px solid var(--vert-500);
    cursor: pointer;
}
